import React from 'react';
import {Link} from 'react-router-dom';

import aritfactIMG from '../images/footer/link-artifacts.jpg';
import stewardshipIMG from '../images/footer/link-stewardship.jpg';
import loreIMG from '../images/footer/link-lore.jpg';
import aboutIMG from '../images/footer/link-about.jpg';


function Footer() {

  return (
    <div className="Footer">
      <div className="link-list">
        <Link to="artifacts">
          <div className="link artifacts-link" style={{backgroundImage : `url(${aritfactIMG})`}} >artifacts</div>
        </Link>
        <Link to="stewardship">
          <div className="link stewardship-link" style={{backgroundImage : `url(${stewardshipIMG})`}} >stewardship</div>
        </Link>
        <Link to="lore">
          <div className="link lore-link" style={{backgroundImage : `url(${loreIMG})`}} >lore</div>
        </Link>
        <Link to="about">
          <div className="link about-link" style={{backgroundImage : `url(${aboutIMG})`}} >about</div>
        </Link>
      </div>
      <div className="sub-foot">
        <div className="top">
          <Link to="/">
            <h2 className="title">Browser Histories</h2>
          </Link>
          <p className="copy">©{(new Date().getFullYear())}. Individual artifacts are owned by their creators and used with permission.</p>
        </div>
        <div className="bottom">
          <a href="https://www.paypal.com/paypalme/decodingstigma" target="_blank" className="button">Donate</a>
          <a href="https://decodingstigma.substack.com/" target="_blank" className="button">SubStack</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
