import React, {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import './fonts/FontImport';

import Page from './components/Page';
import Footer from './components/Footer';
import Header from './components/Header';
import Sessions from './components/Artifacts/Sessions';
import Session from './components/Artifacts/Session';



function App() {
  const [page, setPage] = useState(null)
  const [about, setAbout] = useState(null);
  const [lore, setLore] = useState(null);
  const [stewardship, setStewardship] = useState(null);
  const [home, setHome] = useState(null);
  const [artifacts, setArtifacts] = useState(null);
  const [allSessions, setAllSessions] = useState(null)

  /* thows an error if the API call encounters one */
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response);    }
    return response;
  }

  /* Getting data from API call using built in fetch */
  /* eslint-disable no-unused-vars */
  const getPageData = async ( page, parselyUrl) => {
  const response = await fetch(parselyUrl).then(handleErrors)
  .then( response => { return response.json();} )
  .then( (jsonData) => { 
    if(page === 'page'){
      setPage(jsonData);
    }else{
      setAllSessions(jsonData)
    }
  })
  .catch( error => {console.log(error);} ); 
  }
  /* eslint-enable no-unused-vars */

  useEffect( () => {
    getPageData( 'page' , 'https://coolcool.biz/browserhistories/wp-json/wp/v2/pages/');
    getPageData( 'session' , 'https://coolcool.biz/browserhistories/wp-json/wp/v2/sessions?filter[orderby]=date&order=asc');
  }, []);

  useEffect( () => {
    if(page && page.length > 0){
      page.forEach( (el, key) => {
        if(el.slug === 'about'){
          setAbout(el);
        }else if(el.slug === 'lore'){
          setLore(el);
        }else if(el.slug === 'stewardship'){
          setStewardship(el);
        }else if(el.slug === 'home'){
          setHome(el);
        }else if(el.slug === 'artifacts'){
          setArtifacts(el);
        }
    })
    }
  }, [page])

  return (
    <div className="App">
    <Header />
      <Routes>
      {page?.length &&
        <>
          <Route path="/" element={<Page content={home}/>} />
          <Route path="/about" element={ <Page content={about}/> } />
          <Route path="/lore" element={ <Page content={lore}/> } />
          <Route path="/stewardship" element={ <Page content={stewardship}/> } />
          <Route path="/artifacts" element={ <Sessions content={artifacts}/> } />

            {allSessions && (allSessions.map( (session, key) => 
              <Route key={key} path={"/artifacts/"+session.title.rendered.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')} element={ <Session content={session} num={key+1} /> } />
            ))}
          <Route path="*" element={<p>404</p>} />
        </>
      }
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
