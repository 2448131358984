import React, {useState, useEffect} from 'react';

function Lore(props) {

  /* thows an error if the API call encounters one */
  const [background, setBackground] = useState(null);

  useEffect( ()=>{
    if(props.content){
     setBackground(props.content.acf.background_image.url);
    }
  }, [props.content])

  const pageStyle = {
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition : 'top center',
    backgroundImage: `url(${background})`,
  }

  return (
    <div style={pageStyle} className={`Page Page_${props.content ? props.content.title.rendered : ''}`}>
      <div className="mobile-bg" style={pageStyle}></div>
      {props.content &&
      <div className="page_inner">
      {props.content.title.rendered !== 'Home' ? <h1>{props.content.title.rendered}</h1> : '' }
      <div dangerouslySetInnerHTML={{ __html: props.content.content.rendered} }></div>
      </div>
      }
    </div>
  );
}

export default Lore;
