import React, {useState, useEffect} from 'react';

function SessionPreview(props){
const [background, setBackground] = useState(null);

useEffect( ()=>{
	if(props.sessionData.acf.background_image){
	 setBackground(props.sessionData.acf.background_image.url);
	}
}, [props])

  const pageStyle = {
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition : 'top center',
    backgroundImage: `url(${background})`,
  }

	return(
		<div className="Session-Preview">
			<div className="bg-img" style={pageStyle}></div>
			<div className="top-sec">
				{props.sessionData.acf.top_left && 
					<div className="img-wrap top-left">
						<img src={props.sessionData.acf.top_left.url} alt={props.sessionData.acf.top_left.alt} />
							<div className="intro-text">
								<div className="start">
									<div className="button">Session {props.num+1}</div>
									<h2>{props.sessionData.title.rendered}</h2>
								</div>
								<div className="end">
									<p>{props.sessionData.acf.description}</p>
								</div>
							</div>
						{props.sessionData.acf.top_left.caption &&
							<p className="caption">{props.sessionData.acf.top_left.caption}</p>
						}
					</div>
				}
				{props.sessionData.acf.top_right && 
					<div className="img-wrap top-right">
						<img src={props.sessionData.acf.top_right.url} alt={props.sessionData.acf.top_right.alt} />
						{props.sessionData.acf.top_right.caption &&
							<p className="caption">{props.sessionData.acf.top_right.caption}</p>
						}
					</div>
				}
			</div>
				{props.sessionData.acf.preview_text && 
					<div className="preview-text" dangerouslySetInnerHTML={{__html: props.sessionData.acf.preview_text}}>
					</div>
				}
			<div className="bottom-sec">
				{props.sessionData.acf.bottom_left && 
					<div className="img-wrap bottom-left">
						<img src={props.sessionData.acf.bottom_left.url} alt={props.sessionData.acf.bottom_left.alt} />
						{props.sessionData.acf.bottom_left.caption &&
							<p className="caption">{props.sessionData.acf.bottom_left.caption}</p>
						}
					</div>
				}
				{props.sessionData.acf.bottom_right && 
					<div className="img-wrap bottom-right">
						<img src={props.sessionData.acf.bottom_right.url} alt={props.sessionData.acf.bottom_right.alt} />
						{props.sessionData.acf.bottom_right.caption &&
							<p className="caption">{props.sessionData.acf.bottom_right.caption}</p>
						}
					</div>
				}
				
			</div>
			<div className="portal">
				<h2>enter portal</h2>
			</div>
		</div>
		)
}

export default SessionPreview;