import React, {useState, useEffect} from 'react';
import {useLocation, Link} from 'react-router-dom';


function Header() {
  const [headerPos, setHeaderPos] = useState('Down');
  const [show, setShow] = useState('Hide');

  function showAndHide(){
    show === "Hide" ? setShow('Show') : setShow('Hide')
  }

  var oldScrollY = window.scrollY;
  window.onscroll = function(e) {
    if(window.scrollY > 87){
      if(oldScrollY > window.scrollY){
          setHeaderPos("Down");
      } else {
          setHeaderPos("Up");
      }

    }else{
      setHeaderPos("Down");
    }
    oldScrollY = window.scrollY;
  }

  let location = useLocation();
  let pageName = location.pathname.replace("/", "");

  useEffect( ()=> {
    setShow('Hide');
  }, [location])

  return (
    <>
    <div className={`Header ${pageName} ${headerPos} ${show}`}>
        <div className="button" onClick={showAndHide}><span>{show === "Hide" ? "Menu" : "Close"}</span></div>
        <div className="button no-border"><span><Link to="/">Browser Histories</Link></span></div>
        <div className="button"><span><a href="mailto:decodingstigma@protonmail.com">Connect</a></span></div>
        <div className={`hamburger ${show}`} onClick={showAndHide}><span></span><span></span><span></span></div>
    </div>
    <div className={`mainMenu ${show}`} onClick={showAndHide}>
        <Link to="artifacts">
          <h1 className="link artifacts-link" >Artifacts</h1>
        </Link>
        <Link to="stewardship">
          <h1 className="link stewardship-link" >Stewardship</h1>
        </Link>
        <Link to="lore">
          <h1 className="link lore-link" >Lore</h1>
        </Link>
        <Link to="about">
          <h1 className="link about-link" >About</h1>
        </Link>
      </div>
    </>
  );
}

export default Header; 
