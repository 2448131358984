import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

function Session(props) {
  const [background, setBackground] = useState(null);
  const [symbol, setSymbol] = useState(null);

  useEffect( ()=>{
    if(props.content){
     setBackground(props.content.acf.background_image.url);
     setSymbol(props.content.acf.session_image.url);
    }
  }, [props.content])

  const pageStyle = {
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition : 'top center',
    backgroundImage: `url(${background})`,
  }
  const introStyle ={
    backgroundRepeat: 'no-repeat',
    backgroundSize: '41%',
    backgroundPosition : 'center',
    backgroundImage: `url(${symbol})`,
  }

  return (
    <div style={pageStyle} className={`Session Session_${props.content ? props.content.title.rendered : ''} ${props.content.acf.classes}`}>
      <div className="mobile-bg" style={pageStyle}></div>
      {props.content &&
      <div className="session_inner">

        <div className="session_intro" style={introStyle}>
          <div className="intro-text">
            <div className="start">
              <div className="button">Session {props.num}</div>
              <h1>{props.content.title.rendered}</h1>
            </div>
            <div className="end">
              <p>{props.content.acf.description}</p>
            </div>
          </div>

        </div>
        <div dangerouslySetInnerHTML={{ __html: props.content.content.rendered} }></div>
        <div className="exit" style={{backgroundImage: `url(${symbol})`}}>
          <Link className="exit-int" to="/artifacts">
            <h2>exit portal</h2>
          </Link>
        </div>
      </div>
      }
    </div>
  );
}

export default Session;
