import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import SessionPreview from './SessionPreview';


function Sessions(props) {
  const [sessions, setSessions] = useState(null);
  const [background, setBackground] = useState(null);

  useEffect( ()=>{
    if(props.content){
     setBackground(props.content.acf.background_image.url);
    }
  }, [props.content])

  const pageStyle = {
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition : 'top center',
    backgroundImage: `url(${background})`,
  }

  /* thows an error if the API call encounters one */
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response);    }
    return response;
  }

  /* Getting data from API call using built in fetch */
  /* eslint-disable no-unused-vars */
  const getPageData = async (parselyUrl) => {
  const response = await fetch(parselyUrl).then(handleErrors)
  .then( response => { return response.json();} )
  .then( (jsonData) => { 
    setSessions(jsonData);
  })
  .catch( error => {console.log(error);} ); 
  }
  /* eslint-enable no-unused-vars */

  useEffect( () => {
    getPageData('https://coolcool.biz/browserhistories/wp-json/wp/v2/sessions?filter[orderby]=date&order=asc');
  }, []);

  const titleChange = (title) =>{
    title = title.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
    return title;
  }
 

  return(
    <div style={pageStyle} className={`Page Page_${props.content ? props.content.title.rendered : ''}`}>
    <div className="mobile-bg" style={pageStyle}></div>
      {props.content &&
      <div className="page_inner">
      {props.content.title.rendered !== 'Home' ? <h1>{props.content.title.rendered}</h1> : '' }
      <div dangerouslySetInnerHTML={{ __html: props.content.content.rendered} }></div>
      </div>
      }

      { sessions && (sessions.map( (session, key) =>
        <div className="Session-Preview-Wrapper" key={key}>
        <Link to={"/artifacts/"+ titleChange(session.title.rendered)}>
          <SessionPreview num={key} sessionData={session} />
        </Link>
        </div>
        ))
      }
    </div>
    );
}

export default Sessions;